import * as React from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component";
import pMinDelay from 'p-min-delay'

import Footer from "../sections/Footer"

const GTMHeadCode = loadable(() =>
  pMinDelay(import('../../data/GTMHeadCode'), 5000)
)

const GTMBodyCode = loadable(() =>
  pMinDelay(import('../../data/GTMBodyCode'), 5000)
)

const Layout = ({ children }) => (
  <>
    <GTMHeadCode/>
    <GTMBodyCode/>
    <div>
      <main>
        {children}
      </main>
    </div>
    <Footer enableRequestQuote={false} />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout