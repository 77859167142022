import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaPinterestP,
  FaHouzz,
} from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";

const getBackgroundByType = (type) => {
  switch (type) {
    case "linkedin":
      return "bg-linkedIn";
    case "twitter":
      return "bg-twitter";
    case "insta":
      return "bg-insta";
    case "pinterest":
      return "bg-pinterest";
    case "houzz":
      return "bg-houzz";
    case "googlemaps":
      return "bg-linkedIn";
    default:
      return "bg-facebook";
  }
};

const getIconByType = (type) => {
  switch (type) {
    case "linkedin":
      return <FaLinkedinIn />;
    case "twitter":
      return <FaTwitter />;
    case "insta":
      return <FaInstagram />;
    case "pinterest":
      return <FaPinterestP />;
    case "houzz":
      return <FaHouzz />;
    case "googlemaps":
      return <SiGooglemaps />;
    default:
      return <FaFacebookF />;
  }
};

const SocialButtons = ({ type, link, ariaLabel }) => {
  return (
    <div
      className={`rounded-full h-7 w-7 
      ${getBackgroundByType(type)} 
      flex items-center justify-center`}
    >
      <a className="button-action" href={link} target="_blank" rel="noreferrer" aria-label={ariaLabel}>{getIconByType(type)}</a>
    </div>
  );
};

export default SocialButtons;
