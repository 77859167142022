import React, { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

const ScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.documentElement.scrollTop >= 1000) setScrollTop(true);
      else if (scrollTop === true) setScrollTop(false);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div
      className={`fixed transition-all duration-700 ease-in-out ${
        scrollTop ? "right-0" : "-right-10"
      } bottom-28 w-10 h-10 z-50 text-white`}
    >
      <button
        aria-label="Scroll Up"
        onClick={() => {
          scroll.scrollToTop({ delay: "100", duration: "400", smooth: true });
        }}
        className={`flex items-center text-2xl justify-center w-full h-full bg-black rounded-md bg-opacity-75 cursor-pointer
            transition-all duration-1000 ease-in-out ${
              scrollTop ? "opacity-100" : "opacity-0"
            } focus:outline-none`}
      >
        <FaAngleUp />
      </button>
    </div>
  );
};

export default ScrollTop;
