import React from "react";
import loadable from "@loadable/component";
import pMinDelay from 'p-min-delay'

import logoImage from "../../images/NWP-logo.webp";
import ContentContainer from "../container/ContentContainer";
import ScrollTop from "../controls/ScrollTop";
import { NavLogo } from "../controls/Navigation";
import { Link } from "gatsby";
import RequestQuote from "../controls/RequestQuote";
import SocialButtons from "../controls/SocialButtons";

const SubscribeForm = loadable(() =>
  pMinDelay(import("../forms/SubscribeForm"), 3000)
);

const Footer = ({ enableRequestQuote = false }) => {
  return (
    <>
      <ContentContainer
        containerClassName="w-full bg-gray4 flex justify-center p-4 pt-10 lg:pb-10 lg:px-20 "
        className="text-white w-full flex flex-col items-center justify-center lg:space-y-10"
      >
        <div className="flex flex-col lg:flex-row w-full lg:w-1000px">
          <div className="hidden lg:flex flex-col space-y-1 w-full lg:w-3/12 items-center md:items-start pb-10 md:pb-0">
            <NavLogo image={logoImage} width="100px" />
            <p>National Wood Products</p>
            <a href="https://goo.gl/maps/xybmsxwNP374L1ub6" target="_blank" rel="noreferrer">
              <p className="text-white">1 Latitude Way,</p>
              <p className="text-white">Corona, CA 92881</p>
            </a>
            <a href="tel:909-287-7906"><p className="text-white">(909) 287-7906</p></a>

            <div className="pt-6">
              <p className="text-gray-500 text-xs text-center">Website by <a href="https://goo.gl/maps/xybmsxwNP374L1ub6" className="hover:text-white" target="_blank" rel="noreferrer">fastweb.dev</a></p>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-3/12 gap-y-4 pb-10 lg:pb-0">
            <p className="hidden lg:block font-bold text-center sm:text-left">
              LEARN MORE
            </p>
            <div className="flex flex-row lg:flex-col gap-4 justify-center">
              <Link className="button-action" to="/">
                Home
              </Link>
              <Link className="button-action" to="/about">
                About
              </Link>
              <Link className="button-action" to="/blog">
                Blog
              </Link>
              <Link className="button-action" to="/jobs">
                Jobs
              </Link>
              <Link className="button-action" to="/contact">
                Contact
              </Link>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-3/12 space-y-4 pb-0 items-center lg:items-start">
            <p className="font-bold text-center sm:text-left">PRODUCTS</p>
            <Link className="button-action" to="/products/textured-melamine">
              Textured Melamine
            </Link>
            <Link className="button-action" to="/products/hardwood-plywood">
              Hardwood Plywood
            </Link>
            <Link className="button-action" to="/products/hardwood-lumber">
              Hardwood Lumber
            </Link>
            <Link className="button-action" to="/products/mdf">
              MDF Products
            </Link>
            <Link className="button-action" to="/products/lacquered-boards">
              Lacquered Panels
            </Link>
            <Link className="button-action" to="/products/melamine-panels">
              Melamine Panels
            </Link>
          </div>
          <div className="flex flex-col w-full lg:w-3/12 space-y-4 pb-10 lg:pb-0">
            <p className="hidden sm:block font-bold text-center lg:text-left">
              FOLLOW US
            </p>
            <div className="flex flex-row space-x-2 justify-center items-center">
              <SocialButtons
                type="fb"
                link="https://www.facebook.com/NationalWoodProductsIncSoCal/"
                ariaLabel="Facebook"
              />
              <SocialButtons
                type="linkedin"
                link="https://www.linkedin.com/company/18113261/"
                ariaLabel="LinkedIn"
              />
              <SocialButtons
                type="twitter"
                link="https://twitter.com/nwpsocal"
                ariaLabel="Twitter"
              />
              <SocialButtons
                type="insta"
                link="https://www.instagram.com/nwpsocal/"
                ariaLabel="Instagram"
              />
              <SocialButtons
                type="pinterest"
                link="https://www.pinterest.com/nwpsocal/"
                ariaLabel="Pinterest"
              />
              <SocialButtons
                type="houzz"
                link="https://www.houzz.com/pro/nwpsocallb/national-wood-products"
                ariaLabel="Houzz"
              />
              <SocialButtons
                type="googlemaps"
                link="https://goo.gl/maps/xybmsxwNP374L1ub6"
                ariaLabel="Google Maps"
              />
            </div>
            <SubscribeForm
              buttonClassName="bg-actionBlue text-white text-4xl p1"
              title="Get email updates"
              placeHolderClassName="placeholder-gray-500"
            />
          </div>

          <div className="flex lg:hidden flex-col space-y-1 w-full items-center pb-10 mt-10 text-sm sm:text-base">
            <NavLogo image={logoImage} width="100px" />
            <a href="https://goo.gl/maps/xybmsxwNP374L1ub6" target="_blank" rel="noreferrer">
              <p className="text-white text-center pt-4">National Wood Products</p>
              <p className="text-white text-center">1 Latitude Way,</p>
              <p className="text-white text-center">Corona, CA 92881</p>
            </a>
            <a href="tel:909-287-7906"><p className="text-white text-center">(909) 287-7906</p></a>            
          </div>

          <div className="flex lg:hidden flex-colw-full items-center justify-center pb-4 text-xs">
            <p className="text-gray-500">Website by <a href="https://goo.gl/maps/xybmsxwNP374L1ub6" target="_blank" rel="noreferrer">fastweb.dev</a></p>
          </div>
        </div>
      </ContentContainer>
      <ScrollTop />
      {enableRequestQuote && <RequestQuote link="/contact" />}
    </>
  );
};

export default Footer;
