import React from "react";
import ButtonArrow from "./ButtonArrow";

const RequestQuote = () => {
    return (        
        <ButtonArrow to="/contact" className="flex md:hidden items-center justify-center box-border 
        border-2 px-5 py-2 text-white text-sm font-semibold
        fixed w-full left-0 bottom-0 bg-blue2 z-40 lg:z-auto border-blue2 lg:border-white lg:bg-white"
        text="Request FREE Quote " />
    )
}

export default RequestQuote;