import React from "react";

import logoImage from "../../images/NWP-logo.webp";
import ContentContainer from "../container/ContentContainer";
import { NavBar, NavItem, NavLogo } from "../controls/Navigation";
require("../controls/Navigation");

const Header = ({isHome, isContact}) => {
  
  return (
    <ContentContainer containerClassName="w-full max-w-screen-2xl px-6 h-32 z-30 mx-auto" className="h-32">
      <NavBar
        actionClassName="text-gray1 h-1/2 xl:h-full px-4 py-6 rounded-sm text-xs lg:text-base
            lg:bg-opacity-60 lg:hover:bg-opacity-100 font-semibold flex justify-center items-center"
        logoClassName="flex w-28 h-28 justify-center items-center relative top-2"
        className="w-full h-16 flex flex-row px-2 lg:px-0 z-30"
        supportMobile={true}
        isHome={isHome}
        isContact={isContact}
      >
        <NavLogo name="NavLogo" link="/" image={logoImage} width="100px" className />
        <NavItem
          name="NavItem"
          link="/about"
          className={`px-4 font-sans ${isHome? "text-white" : "text-gray1"} text-base font-semibold flex items-end`}
          ariaLabel="About"
        >
          About
        </NavItem>
        <NavItem
          name="NavItem"
          link="/products"
          className={`px-4 font-sans ${isHome? "text-white" : "text-gray1"} text-base font-semibold flex items-end`}
          ariaLabel="Products"
        >
          Products
        </NavItem>
        <NavItem
          name="NavItem"
          link="/blog"
          className={`px-4 font-sans ${isHome? "text-white" : "text-gray1"} text-base font-semibold flex items-end`}
          ariaLabel="Blog"
        >
          Blog
        </NavItem>
        <NavItem
          name="NavItem"
          link="/forms"
          className={`px-4 font-sans ${isHome? "text-white" : "text-gray1"} text-base font-semibold flex items-end`}
          ariaLabel="Forms"
        >
          Forms
        </NavItem>
        <NavItem
          name="NavItem"
          link="/jobs"
          className={`px-4 font-sans ${isHome? "text-white" : "text-gray1"} text-base font-semibold flex items-end`}
          ariaLabel="Jobs"
        >
          Jobs
        </NavItem>
        <NavItem
          name="NavItem"
          link="/contact"
          className={`px-4 font-sans ${isHome? "text-white" : "text-gray1"} text-base font-semibold flex items-end`}
          ariaLabel="Contact"
        >
          Contact
        </NavItem>
      </NavBar>
    </ContentContainer>
  );
};

export default Header;
