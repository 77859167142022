import { Link } from "gatsby";
import React from "react";
import { MdArrowForward } from "react-icons/md";

const ButtonArrow = ({ href, to, className, newWindow, ariaLabel }) => {

  return (
    <>
      {href !== undefined ? (
        <a
          className="button-action"
          href={href}
          target={newWindow ? "_blank" : ""}
          rel="noreferrer"
          aria-label={ariaLabel}
        >
          <div className={`${className ? className : "p-2 text-4xl"} rounded-full`}>
            <MdArrowForward />
          </div>
        </a>
      ) : (
        <Link className="button-action" to={to} aria-label={ariaLabel}>
          <div className={`${className ? className : "p-2 text-4xl"} rounded-full`}>
            <MdArrowForward />
          </div>
        </Link>
      )}
    </>
  );
};

export default ButtonArrow;
